.App {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background-color: #282c34;
  display: flex;
}

.Title {
  min-width: 100%;
  background-color: cadetblue;
  color: black;
}
.App-cardZone {
  min-height: 50vh;
  width: 80vh;
  /* min-width: 100vh; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}
.SearchForm {
  /* margin-top: auto; */
  display: flex;
  color: black;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80vh;
}

.SearchRow {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.fightLog {
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 50vw;
  min-width: 500px!important;
  height: 30vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #000;
  border: 1px solid #000;
  color: #c087fa;
  padding: 8px;
  font-family: courier new;
  font-size: calc(1em * 0.8);
}

.fightLogHeader {
  background-color: #9b3cfa;
  color: black;
  font-weight: bold;
}

.fightLogMessage {
  margin-bottom: 0;
}

.clearButton {
  position: absolute;

  right: 8px;
  top: 50px;
}

.footer {
  font-size: 0.8em;
  text-align: right;
  padding: 5px;
  background-color: #222;
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.header {
  font-weight: bold;
  color: #9b3cfa;
  font-size: 0.8em;
  text-align: center;
  padding: 5px;
  background-color: #222;
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.helpButton {
  position: relative;
  top: 5vh;
  left: 50vh;
  font-weight: bold;

}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.formControl {
  text-align: center;
}

.blinking {
  animation: blinker 0.2s linear;
  animation-iteration-count: 1;
}

@keyframes blinker {
  50% {
    opacity: 0;
    background-color: #282c34;
  }
}
